exports.install = function(Vue) {
	Vue.prototype.post = function(url, callback) {
		//创建实例
		var _this = this;
		let api =
			"http://website.msgj.top/"  //线上地址
		_this.$toast.loading({
			forbidClick: true,
			duration: 0
		});
		var ajx = _this.$ajax.create({
			headers: {},
			cache: false //不进行缓存
		});
		console.log(api+url)

		//想获取服务端列表
		ajx
			.get(api+url)
			.then(res => {
				_this.$toast.clear();
				callback(res.data);
				// console.log(url + '===Result' + JSON.stringify(res.data))
			})
			.catch(error => {
				// _this.$router.push("/");
				console.log(error);
			});
	};
	Vue.prototype.getUrlData = function(name) {
		var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
		var r = window.location.search.substr(1).match(reg);
		if (r != null) return unescape(r[2]);
		return null;
	}
}
