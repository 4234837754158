<template>
	<div>
		<NavigationBar></NavigationBar>
		<div class="banner">
			<div class="box">
				<div class="banner_name">联系我们</div>
			</div>
		</div>
		<breadNav :tabList='tabList' @tabClick='tabClick'></breadNav>
		<div class="about_con box">
			<div class="n_content_top leftAndRight">
				<div class="n_content_t_l">
					<div class="n_content_t_l_name">联系方式</div>
					<div class="n_content_t_l_p">公司名称：湖南美盛国际健康产业有限公司</div>
					<div class="n_content_t_l_p n_content_t_l_p2">地址：长沙市望城经济技术开发区普瑞大道一段1555号金桥市场集群一区6栋14层1446号</div>
					<div class="n_content_t_l_p">联系电话：400-6007-607</div>
					<div class="n_content_t_l_p n_content_t_l_p2">传真：</div>
					<div class="n_content_t_l_p">网址：http://site.msgj.top</div>
					<div class="n_content_t_l_p">Email：a18974831319@163.com</div>
				</div>
				<div class="n_content_t_r">
					<div class="n_content_t_r_name">在线留言</div>
					<div class="n_content_t_r_input">
						<input name="name" v-model="name" type="text" placeholder="姓名">
					</div>
					<div class="n_content_t_r_input">
						<input name="tel" v-model="tel" type="text" placeholder="联系方式">
					</div>
					<div class="n_content_t_r_input">
						<textarea name="content" v-model="content" placeholder="内容"></textarea>
					</div>
					<div class="n_content_t_r_input2">
						<input type="submit" name="dosubmit" value="提  交" @click="tj">
					</div>
				</div>
			</div>
		</div>
		<img class="dt" src="../../../public/img/dt.png" />
		<foot></foot>
	</div>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue'
	import foot from '@/components/foot.vue'
	import breadNav from '@/components/breadNav.vue'
	export default {
		components: {
			NavigationBar,
			foot,
			breadNav

		},
		data() {
			return {
				tabList: [],
				name: '',
				tel: '',
				content: ''
			}
		},
		methods: {
			tabClick(data) {
				console.log(data)
			},
			tj() {
				if (this.name == '') {
					alert('请输入姓名')
					return;
				}
				if (this.tel == '') {
					alert('请输入电话')
					return;
				}
				if (this.content == '') {
					alert('请输入内容')
					return;
				}
				let _this = this;
				let urls =
					"api/article/addmsg?true_name=" + _this.name+'&mobile='+_this.tel+'&message='+this.content
				_this.post(urls, res => {
					console.log(res)
					alert('提交成功')
					_this.$router.push({
						path: '/',
					})
				})
			},
		}
	}
</script>

<style scoped>
	.banner {
		background: url('../../../public/img/top.png');
		width: 100%;
		height: 340px;
	}

	.banner_name {
		padding-top: 150px;
		font-size: 44px;
		color: #474747;
		text-align: left;
	}

	.about_con {
		margin: 30px auto;
		text-align: left;
	}

	.n_content_top {
		margin: 50px 0 18px 0;
		padding: 45px 80px;
		background-color: #fbfbfb;
		text-align: left;
		align-items: baseline;
	}

	.n_content_t_l {

		width: 530px;
	}

	.n_content_t_l_name {
		font-size: 20px;
		color: #292929;
		font-weight: bolder;
		margin-bottom: 25px;
	}

	.n_content_t_l_p {
		font-size: 15px;
		color: #5f5f5f;
		line-height: 24px;
	}

	.n_content_t_l_p2 {
		padding-bottom: 24px;
	}

	.n_content_t_r_name {
		font-size: 20px;
		color: #292929;
		font-weight: bolder;
		margin-bottom: 15px;
	}

	.n_content_t_r_input {
		margin-bottom: 19px;
	}

	.n_content_t_r_input textarea {
		padding-left: 15px;
		padding-top: 14px;
		width: 500px;
		height: 94px;
		max-height: 94px;
		border: 1px solid #EBEBEB;
	}

	.n_content_t_r_input input {
		width: 500px;
		padding-left: 15px;
		height: 43px;
		border: 1px solid #EBEBEB;
	}

	input:not([type=radio]):not([type=checkbox]),
	select {
		vertical-align: middle;
	}

	.n_content_t_r_input2 input {
		width: 150px;
		outline: none;
		height: 42px;
		font-size: 15px;
		border-radius: 20px;
		text-align: center;
		border: none;
		line-height: 42px;
		background-color: #c9151e;
		color: #ffffff;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-ms-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
	}

	.dt {
		width: 1200px;
		margin: 0 auto 80px;
	}
</style>